@font-face {
    font-family: "strawfordblack_italic";
    src: url("/fonts/strawford-italic/strawford-blackitalic-webfont.eot");
    src: url("/fonts/strawford-italic/strawford-blackitalic-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/strawford-italic/strawford-blackitalic-webfont.woff2") format("woff2"),
        url("/fonts/strawford-italic/strawford-blackitalic-webfont.woff") format("woff"),
        url("/fonts/strawford-italic/strawford-blackitalic-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: strawfordblack;
    src: url("/fonts/strawford-roman/strawford-black-webfont.eot");
    src: url("/fonts/strawford-roman/strawford-black-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/strawford-roman/strawford-black-webfont.woff2") format("woff2"),
        url("/fonts/strawford-roman/strawford-black-webfont.woff") format("woff"),
        url("/fonts/strawford-roman/strawford-black-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: strawfordbold;
    src: url("/fonts/strawford-roman/strawford-bold-webfont.eot");
    src: url("/fonts/strawford-roman/strawford-bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("/fonts/strawford-roman/strawford-bold-webfont.woff2") format("woff2"),
        url("/fonts/strawford-roman/strawford-bold-webfont.woff") format("woff"),
        url("/fonts/strawford-roman/strawford-bold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: strawfordregular;
    src: url("/fonts/strawford-roman/strawford-regular-webfont.eot");
    src: url("/fonts/strawford-roman/strawford-regular-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/strawford-roman/strawford-regular-webfont.woff2") format("woff2"),
        url("/fonts/strawford-roman/strawford-regular-webfont.woff") format("woff"),
        url("/fonts/strawford-roman/strawford-regular-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

html {
    font-size: 18px;
}

body {
    font-size: 18px;
    font-family: strawfordregular, sans-serif;
    color: $color-text;
    word-break: break-word;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
    @include heading-1;

    color: $color-headings;
}

h2,
.h2 {
    @include heading-2;

    color: $color-headings;
}

h3,
.h3 {
    @include heading-3;

    color: $color-headings;
}

h4,
.h4 {
    color: $color-headings;
}

h5,
.h5 {
    color: $color-headings;
}

h6,
.h6 {
    color: $color-headings;
}

p,
.paragraph {
    @include paragraph;
}

a {
    color: var(--prezly-accent-color);
}

.textSmall {
    @include text-small;
}

.textTiny {
    @include text-tiny;
}

label,
.label {
    @include text-label;
}
